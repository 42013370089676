import $api, { API_URL } from '../../shared/api/axios';
import { AxiosResponse } from 'axios';
import { AuthResponse } from '../../entities/types/IAuthResponse';
import { IBackResponse } from '../types/response';

export default class AuthService {
  static async auth(
    email: String,
    password: String
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>(`${API_URL.main}/session/login`, { email, password });
  }

  static async signout(): Promise<void> {
    return $api.post(`${API_URL.main}/session/logout`);
  }

  static async changePassword(
    curr_password: String,
    new_password: String
  ): Promise<AxiosResponse<IBackResponse>> {
    return $api.post(`${API_URL.main}/user/changepassword`, { curr_password, new_password });
  }
}
