import { Navigate, redirect, useNavigate } from 'react-router-dom';
import storageUser from '../../app/storage/User';
import B1Button from '../../shared/ui/button';
import B1Input from '../../shared/ui/input';
import { useContext, useState } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const AuthForm: React.FC = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onFinish = async () => {
    user.setLoading(true);
    try {
      user.auth(login, password);
    } finally {
      user.setLoading(false);
    }

    navigate('/');
  };

  return (
    <div
      style={{
        padding: '40px',
        width: '400px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        borderRadius: '8px',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>Вход в учетную запись</span>
      <B1Input
        placeholder='Логин'
        onChange={(i) => setLogin(i.target.value)}
      />
      <B1Input
        placeholder='Пароль'
        onChange={(i) => setPassword(i.target.value)}
        type={'password'}
      />
      <B1Button
        placeholder='Войти'
        onClick={onFinish}
        style={{height: '32px'}}
      />
    </div>
  );
};

export default observer(AuthForm);
