interface B1Input {
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[] | undefined;
  defaultValue?: string | number | readonly string[] | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
}

const B1Input: React.FC<B1Input> = ({ placeholder, onChange, type, value, defaultValue }) => {
  return (
    <input
      placeholder={placeholder}
      style={{
        height: '40px',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '14px',
        backgroundColor: '#FAFAFA',
        borderRadius: '4px',
        border: '0',
        borderBottom: '2px solid rgba(0,0,0,0.4)',
      }}
      onChange={onChange}
      type={type}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default B1Input;
