import { observer } from 'mobx-react-lite';
import WidgetAuth from '../../widgets/auth';

const PageAuth: React.FC = () => {
  return (
    <div style={{ background: 'linear-gradient(180deg, #ededed 0%, rgba(237, 237, 237, 0.9) 51.56%, rgba(237, 237, 237, 0.6) 100%), url("/img/B1-Pattern-Grey-Light.svg")', height: '100vh', width: '100wv' }}>
      <WidgetAuth />
    </div>
  );
};

export default observer(PageAuth);