import { useState } from 'react';

interface MenuPoint {
  placeholder: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const MenuPoint: React.FC<MenuPoint> = ({ placeholder, onClick, disabled }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    if (!disabled) {
      setIsHovering(true);
    }
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '280px',
        height: '40px',
        backgroundColor: disabled ? '#ddd' : isHovering ? '#37AB49' : '#F2F2F2',
        color: disabled ? '#aaa' : isHovering ? 'white' : 'black',
        borderRadius: '4px',
        paddingLeft: '10px',
        cursor: disabled ? 'default' : 'pointer'
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick && !disabled ? onClick : undefined}
    >
      {placeholder}
    </div>
  );
};

export default MenuPoint;
