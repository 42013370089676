import $api, { API_URL } from '../../shared/api/axios';
import { AxiosResponse } from 'axios';
import { IBackResponse } from '../types/response';

export default class AdminService {
  static async createUser(email: string, password: string, name: string, surname: string): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/admin/user/create`, {
      email,
      password,
      name,
      surname
    });
  }

  static async manualActivate(userId: string, method: 'set' | 'remove'): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/admin/user/manualActivate`, {
      userId,
      method,
    });
  }

  static async setAdmin(userId: string, method: 'set' | 'remove'): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/admin/user/setadmin`, {
      userId,
      method,
    });
  }

  static async userBlock(userId: string, method: 'set' | 'remove'): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/admin/user/block`, {
      userId,
      method,
    });
  }
}
