import { RouteObject } from "react-router-dom"
import PageAuth from "../../pages/auth/index";
import PageHome from "../../pages/home";
import PageRecognition from "../../pages/recognition";
import PageError404 from "../../pages/error404";
import PageRecognitionObject from "../../pages/recognitionObject";
import PageError500 from "../../pages/error500";
import PageUsers from "../../pages/users";

export const routesLogged: RouteObject[] = [
    {
      path: '/',
      element: <PageRecognition />,
      errorElement: <PageError404 />,
    },
    {
      path: '/auth',
      element: <PageAuth />,
      errorElement: <PageError500 />
    },
    {
      path: '/recognition',
      element: <PageRecognition />,
      errorElement: <PageError500 />
    },
    {
      path: '/recognition/:recognitionObject',
      element: <PageRecognitionObject />,
      errorElement: <PageError500 />
    }
]

export const routesAdmin: RouteObject[] = [
  {
    path: '/admin/users',
    element: <PageUsers />,
    errorElement: <PageError500 />
  }
]

export const routesUnlogged: RouteObject[] = [
    {
      path: '*',
      element: <PageAuth />
    }
]