import axios from 'axios';
import { AuthResponse } from '../../entities/types/IAuthResponse';

const test: number = 1
const url = test === 0 ? 'http://localhost' : 'https://ai.b1-it.ru'

export const API_URL = {
  main: `${url}:5025`,
}

const $api = axios.create({
  withCredentials: true,
});

$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return config;
});

$api.interceptors.response.use(
  (config: any) => {
    return config;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get<AuthResponse>(
          `${API_URL.main}/session/refresh`,
          { withCredentials: true }
        );
        localStorage.setItem('token', response.data.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        console.log('Not authorized');
      }
    }

    throw error;
  }
);

export default $api;
