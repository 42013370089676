export const formatDate = (str: string) => {
  const timestamp = new Date(str);
  timestamp.setHours(timestamp.getHours() + 3);

  // Разбиваем строку на дату и время
  const [date, time] = timestamp.toISOString().split('T');

  // Разбиваем дату на день, месяц и год
  const [year, month, day] = date.split('-');

  // Разбиваем время на часы, минуты и секунды
  const [hours, minutes, seconds] = time.split(':');

  // Формируем строку в желаемом формате
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds.split('.')[0]}`;
};

export const calculateTimeDiff = (startDateStr: string, endDateStr: string) => {
  // Преобразуем строки в объекты Date
  const startDate: any = new Date(startDateStr);
  const endDate: any = new Date(endDateStr);

  // Вычисляем разницу во времени в миллисекундах
  const timeDiffMs = endDate - startDate;

  // Преобразуем миллисекунды в часы, минуты и секунды
  const hours = Math.floor((timeDiffMs / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiffMs / (1000 * 60)) % 60);
  const seconds = Math.ceil((timeDiffMs / 1000) % 60);

  // Формируем строку в желаемом формате
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const toHHMMSS = (str: string) => {
  // Разбиваем строку на части
  const seconds = str.split('.')[0]

  // Преобразуем секунды в минуты и часы
  const totalSeconds = parseInt(seconds);
  const hoursFromSeconds = Math.floor(totalSeconds / 60 / 60);

  const minutesFromSeconds = Math.floor(totalSeconds / 60) - hoursFromSeconds * 60;
  const remainingSeconds = totalSeconds % 60;

  // Добавляем ноль к часам и минутам, если их длина меньше 2
  const formattedHours = hoursFromSeconds.toString().length === 1 ? `0${hoursFromSeconds}` : hoursFromSeconds;
  const formattedMinutes = minutesFromSeconds.toString().length === 1 ? `0${minutesFromSeconds}` : minutesFromSeconds;
  const formattedSeconds = remainingSeconds.toString().length === 1 ? `0${remainingSeconds}` : remainingSeconds;

  // Возвращаем строку в формате HH:MM:SS
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
