import { useParams } from 'react-router-dom';
import RecognitionService from '../../entities/model/RecognitionService';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { toHHMMSS } from '../../shared/lib/date';
import { Input, Switch } from 'antd';
import B1Button from '../../shared/ui/button';

const PageRecognitionObject: React.FC = () => {
  const param = useParams();

  const [textA, setTextA] = useState<any>([]);
  const [textB, setTextB] = useState<any>([]);

  const [isTimeline, setIsTimeline] = useState<boolean>(false);
  const [isChannel, setIsChannel] = useState<boolean>(true);

  const [text, setText] = useState<string>('');

  const handleCheck = () => {
    if (isChannel) {
      if (isTimeline) {
        setText(textA.map((line: any, index: any) => `${toHHMMSS(line.startTime)}: ${line.text}`).join('\n'));
      } else {
        setText(textA.map((line: any, index: any) => line.text).join('\n'));
      }
    } else {
      if (isTimeline) {
        setText(textB.map((line: any, index: any) => `${toHHMMSS(line.startTime)}: ${line.text}`).join('\n'));
      } else {
        setText(textB.map((line: any, index: any) => line.text).join('\n'));
      }
    }
  };

  const handleFetch = async () => {
    if (param.recognitionObject) {
      const response = await RecognitionService.getText(param.recognitionObject);
      if (response.data.code === 1) {
        setTextA(response.data.data[1]);
        setTextB(response.data.data[2]);
      }
    }
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const handleDownload = () => {
    const blob = new Blob([text], { type: 'text/plain' });
    saveAs(blob, `transcript-1.txt`);
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(handleCheck, [isChannel, isTimeline]);

  return (
    <div style={{ padding: '25px', paddingLeft: '80px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }}>
        <B1Button
          placeholder='Получить'
          onClick={handleCheck}
          style={{ width: '150px', height: '32px' }}
        />
        <B1Button
          type='Secondary'
          placeholder='Скачать .TXT'
          onClick={handleDownload}
          style={{ width: '150px', height: '32px' }}
        />
        <Switch
          checkedChildren='Таймлайн включен'
          unCheckedChildren='Таймлайн выключен'
          checked={isTimeline}
          onChange={setIsTimeline}
          style={{ width: '180px' }}
        />
        <Switch
          checkedChildren='Канал 1'
          unCheckedChildren='Канал 2'
          checked={isChannel}
          onChange={setIsChannel}
          style={{ width: '180px' }}
        />
      </div>
      <Input.TextArea
        value={text}
        onChange={handleChange}
        style={{ height: '1000px' }}
      />
    </div>
  );
};

export default PageRecognitionObject;
