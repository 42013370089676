import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { AuthResponse } from '../../entities/types/IAuthResponse';
import { API_URL } from '../../shared/api/axios';
import { IUser } from '../../entities/types/IUser';
import { notification } from 'antd';
import AuthService from '../../entities/model/AuthService';

class StorageUser {
  user = {} as IUser;
  isLoading: boolean = false;
  isLogged: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(state: boolean) {
    this.isLogged = state;
  }

  setLoading(state: boolean) {
    this.isLoading = state;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  async auth(email: string, password: string) {
    try {
      const response = await AuthService.auth(email, password);

      switch (response.data.code) {
        case 1: {
          localStorage.setItem('token', response.data.data.accessToken);
          this.setAuth(true);
          this.setUser(response.data.data.user);
          return notification.success({
            message: 'Успешная авторизация',
            description: response?.data.text,
          });
        }
        default:
          return notification.error({
            message: 'Ошибка при попытке авторизации',
            description: response?.data.text,
          });
      }
    } catch (e: any) {
      notification.error({
        message: 'Ошибка',
        description: e.response?.data?.message,
      });
      console.log(e.response?.data?.message);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get<AuthResponse>(`${API_URL.main}/session/refresh`, { withCredentials: true });
      switch (response.data.code) {
        case 1: {
          localStorage.setItem('token', response.data.data.accessToken);
          this.setAuth(true);
          this.setUser(response.data.data.user);
          break;
        }
        default:
          return notification.error({
            message: 'Ошибка при попытке авторизации',
            description: response?.data.text,
          });
      }
    } catch (e: any) {
      console.log(e.response?.data?.message);
      this.signout();
    } finally {
      this.setLoading(false);
    }
  }

  async signout() {
    try {
      await AuthService.signout();
      localStorage.removeItem('token');
      this.setAuth(false);
      this.setUser({} as IUser);
    } catch (e: any) {
      console.log(e.response?.data?.message);
    }
  }
}

export default StorageUser;
