import { observer } from 'mobx-react-lite';
import WidgetAuth from '../../widgets/auth';
import B1Button from '../../shared/ui/button';

const PageError404: React.FC = () => {
  return (
    <div
      style={{
        background:
          'linear-gradient(180deg, #ededed 0%, rgba(237, 237, 237, 0.9) 51.56%, rgba(237, 237, 237, 0.6) 100%), url("/img/B1-Pattern-Grey-Light.svg")',
        height: '100vh',
        width: '100wv',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '30px',
      }}
    >
      <div>
        <img src='/img/404.png' />
      </div>
      <div style={{ fontSize: 24, fontWeight: 600, lineHeight: '29.26px', textAlign: 'center', paddingTop: '30px' }}>Ой, кажется страница не найдена</div>
      <div style={{ fontSize: 14, fontWeight: 400, lineHeight: '18px', textAlign: 'center' }}>Вы можете перейти на главную или назад</div>
      <div style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
        <B1Button
          placeholder='Перейти на главную'
          style={{ height: '32px', width: '200px' }}
          onClick={() => window.location.href = '/'}
        />
        <B1Button
          type='Secondary'
          placeholder='Назад'
          style={{ height: '32px', width: '200px' }}
          onClick={() => window.history.back()}
        />
      </div>
    </div>
  );
};

export default PageError404;
