import { CustomCellRendererProps } from 'ag-grid-react';
import B1Button from '../../shared/ui/button';
import B1Table from '../../shared/ui/table';
import { Progress, Upload, UploadProps, message } from 'antd';
import { API_URL } from '../../shared/api/axios';
import LoadingSpinner from '../../shared/ui/loader';
import { useEffect, useState } from 'react';
import RecognitionService from '../../entities/model/RecognitionService';
import UserService from '../../entities/model/UserService';
import { calculateTimeDiff, formatDate } from '../../shared/lib/date';

const PageRecognition: React.FC = () => {
  const [data, setData] = useState([]);

  async function handleFetch() {
    const response = await RecognitionService.getList();
    if (response.data.code === 1) {
      const records: any = await Promise.all(
        response.data.data.map(async (record: any) => {
          switch (record.status) {
            case '5':
              record.duration = calculateTimeDiff(record.createdAt, record.updatedAt);
              record.finishDate = formatDate(record.updatedAt);
              break;
          }

          const user = await UserService.usernameById(record.author);

          record.author = user.data;
          record.createdAt = formatDate(record.createdAt);

          return record;
        })
      );

      setData(records);
    } else {
      message.error('Ошибка при попытке получения записей');
    }
  }

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div style={{ paddingLeft: '70px', paddingRight: '70px', paddingTop: '25px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <span style={{ fontWeight: 'bold', fontSize: 24 }}>Распознавание по записи</span>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <Upload {...props}>
          <B1Button
            placeholder='Добавить'
            style={{ width: '260px', height: '32px', borderRadius: '4px' }}
          />
        </Upload>
        <B1Button
          placeholder='Обновить'
          type='Secondary'
          style={{ width: '260px', height: '32px', borderRadius: '4px' }}
          onClick={handleFetch}
        />
      </div>
      <div>
        <B1Table
          data={data}
          columns={columns}
          style={{ height: '1000px' }}
        />
      </div>
    </div>
  );
};

export default PageRecognition;

const props: UploadProps = {
  name: 'file',
  accept: 'audio/mpeg, video/mp4',
  action: `${API_URL.main}/recognition/upload`,
  withCredentials: true,
  multiple: false,
  showUploadList: false,
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      <LoadingSpinner />;
    }
    if (status === 'done') {
      if (info.file.type === 'audio/mpeg' || info.file.type === 'video/mp4') {
        // Proceed with valid MP3 or MP4 file
        message.warning(`${info.file.response.text}`);
      } else {
        // Handle invalid file format
        message.error('Invalid file format. Please upload MP3 or MP4 files only.');
      }
    } else if (status === 'error') {
      message.error(`Возникла ошибка при попытке загрузки файла`);
    }
  },
};

const fieldId: React.FC<CustomCellRendererProps> = (params: CustomCellRendererProps) => {
  return (
    <div
      onClick={() => (window.location.href = `/recognition/${params.value}`)}
      style={{ fontWeight: 'bold', cursor: 'pointer', color: 'blue' }}
    >
      {params.value}
    </div>
  );
};

const HighlightExtension = (params: any) => {
  const extension: any = params.value.split('.').pop();
  const color = extension === 'mp4' ? 'red' : 'blue';

  return (
    <span>
      {params.value.slice(0, -extension.length - 1)}
      <span style={{ color }}>.{extension}</span>
    </span>
  );
};

const HighlightStatus = (params: any) => {  
  if(params.value == 0){
    return <Progress steps={5}  percent={0} format={() => <span style={{color: 'red'}}>Ошибка</span>}/>;
  } else if (params.value == 5){
    return <Progress steps={5}  percent={100} format={() => <span style={{color: 'green'}}>Готово!</span>}/>;
  } else {
    return <Progress steps={5}  percent={(params.value / 5) * 100} />;
  }
  
};

const columns = [
  { field: 'id', headerName: 'Идентификатор', cellDataType: 'text', filter: 'agTextColumnFilter', flex: 2, cellRenderer: fieldId },
  { field: 'author', headerName: 'Автор', cellDataType: 'text', filter: 'agTextColumnFilter', flex: 1 },
  {
    field: 'filename',
    headerName: 'Наименование файла',
    cellDataType: 'text',
    cellRenderer: HighlightExtension,
    filter: 'agTextColumnFilter',
    flex: 2,
  },
  { field: 'status', headerName: 'Статус', cellDataType: 'text', cellRenderer: HighlightStatus, filter: 'agDateColumnFilter', flex: 1 },
  { field: 'createdAt', headerName: 'Дата запуска', cellDataType: 'text', filter: 'agDateColumnFilter', flex: 1 },
  { field: 'duration', headerName: 'Длительность обработки', flex: 1 },
  { field: 'finishDate', headerName: 'Дата завершения', flex: 1 },
  //{ field: 'fileDuration', headerName: 'Длительность записи', flex: 1 },
  //{ field: 'cost', headerName: 'Стоимость, руб.', cellDataType: 'number', filter: 'agNumberColumnFilter', flex: 1 },
];
