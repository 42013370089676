import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
//import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useMemo } from 'react';
import { AG_GRID_LOCALE_RU } from './locale';

interface B1Table {
  data: any;
  columns: any;
  style?: React.CSSProperties;
}

const B1Table: React.FC<B1Table> = ({ data, columns, style }) => {
  return (
    <div
      className='ag-theme-alpine'
      style={{ ...style }}
    >
      <AgGridReact
        rowData={data}
        columnDefs={columns}
        pagination={true}
        paginationPageSize={50}
        localeText={AG_GRID_LOCALE_RU}
      />
    </div>
  );
};

export default B1Table;
