import $api, { API_URL } from "../../shared/api/axios";
import { AxiosResponse } from "axios";
import { IBackResponse } from "../types/response";

export default class UserService {
  static async usernameById(id: String): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/user/usernamebyid`, {
      id,
    });
  }

  static async getList(): Promise<AxiosResponse<IBackResponse>> {
    return $api.get<IBackResponse>(`${API_URL.main}/user/getlist`);
  }

  static async setRecognitionBalance(userId: string, count: number): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/user/setrecognitionbalance`, { userId, count });
  }
}
