import { useContext, useEffect, useRef, useState } from 'react';
import MenuPoint from '../../shared/ui/menu'; 
import { Context } from '../..';

const Header: React.FC = () => {
  const { user } = useContext(Context);
  const [stateMenu, setStateMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuToggle = () => {
    setStateMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setStateMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', height: '56px', backgroundColor: '#292628' }}>
        <div
          style={{ width: '56px', backgroundColor: '#444042', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handleMenuToggle}
          ref={menuRef}
        >
          <span style={{ fontSize: 36, color: 'white', userSelect: 'none' }}>=</span>
        </div>
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingLeft: '30px', paddingRight: '30px', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src='/img/B1-Logo-White.svg'
              style={{ width: '24px' }}
            />
            <span style={{ textTransform: 'uppercase', fontSize: 14, color: 'white', userSelect: 'none' }}>Распознавание аудио</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: 'white', userSelect: 'none', cursor: 'pointer' }}>David.Dzhamiev</span>
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', display: stateMenu ? 'flex' : 'none', flexDirection: 'column', gap: '2px', marginTop: '5px', marginLeft: '15px', zIndex: 9999 }}>
        <MenuPoint
          placeholder='Главная'
        />
        <MenuPoint placeholder='Распознавание' onClick={() => window.location.href = "/recognition"}/>
        {
          user.user.admin && <MenuPoint placeholder='Управление пользователями' onClick={() => window.location.href = "/admin/users"}/>
        }
        {/* <MenuPoint
          placeholder='GPT'
          disabled
        /> */}
      </div>
    </>
  );
};

export default Header;
