import { CustomCellRendererProps } from "ag-grid-react";
import B1Button from "../../shared/ui/button";
import B1Table from "../../shared/ui/table";
import {
  InputNumber,
  Modal,
  Progress,
  Switch,
  Upload,
  UploadProps,
  message,
} from "antd";
import { API_URL } from "../../shared/api/axios";
import LoadingSpinner from "../../shared/ui/loader";
import { useEffect, useState } from "react";
import RecognitionService from "../../entities/model/RecognitionService";
import UserService from "../../entities/model/UserService";
import { calculateTimeDiff, formatDate } from "../../shared/lib/date";
import AdminService from "../../entities/model/AdminService";
import B1Input from "../../shared/ui/input";

const PageUsers: React.FC = () => {
  const [data, setData] = useState([]);
  const [isCreateUserModalOpen, setCreateUserModalOpen] =
    useState<boolean>(false);

  const [name, setName] = useState<string | undefined>(undefined);
  const [surname, setSurname] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const handleFetch = async () => {
    const response = await UserService.getList();
    if (response.data.code === 1) {
      setData(response.data.data);
    } else {
      message.error("Ошибка при попытке получения записей");
    }
  };

  const handleUserCreate = async () => {
    if (!name || !email || !password || !surname) {
      return message.error("Заполните все поля");
    }

    const response = await AdminService.createUser(
      email,
      password,
      name,
      surname
    );
    if (response.data.code === 1) {
      message.success("Пользователь успешно создан");
      setCreateUserModalOpen(false);
      handleFetch();
    } else {
      message.error("Ошибка при создании пользователя");
    }
  };

  const Activated: React.FC = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleSwitch = async () => {
      await handleActivated(params);
      await handleFetch();
    };

    return (
      <Switch
        loading={loading}
        checked={params.value}
        onChange={() => handleSwitch()}
      />
    );
  };

  const Blocked: React.FC = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleSwitch = async () => {
      await handleBlock(params);
      await handleFetch();
    };

    return (
      <Switch
        loading={loading}
        checked={params.value}
        onChange={() => handleSwitch()}
      />
    );
  };

  const SetAdmin: React.FC = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleSwitch = async () => {
      await handleAdmin(params);
      await handleFetch();
    };

    return (
      <Switch
        loading={loading}
        checked={params.value}
        onChange={() => handleSwitch()}
      />
    );
  };

  const SetCounter: React.FC = (params: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = async (e: any) => {
      await handleCounter({...params, value: e});
      await handleFetch();
    };

    return <InputNumber min={0} style={{width: '70px'}} value={params.value} onChange={(e) => handleChange(e)} />;
  };

  const columns = [
    {
      field: "action",
      headerName: "Действия",
      cellDataType: "text",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Ид",
      cellDataType: "text",
      filter: "agTextColumnFilter",
      flex: 0.6,
    },
    {
      field: "name",
      headerName: "Имя",
      cellDataType: "text",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "surname",
      headerName: "Фамилия",
      cellDataType: "text",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Почта",
      cellDataType: "text",
      filter: "agTextColumnFilter",
      flex: 1.5,
    },
    {
      field: "activated",
      headerName: "Активирован",
      cellDataType: "boolean",
      cellRenderer: Activated,
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "blocked",
      headerName: "Блок",
      cellDataType: "boolean",
      cellRenderer: Blocked,
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "admin",
      headerName: "Админ",
      cellDataType: "boolean",
      cellRenderer: SetAdmin,
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      field: "recognitionCounter",
      headerName: "Баланс распознаваний",
      cellDataType: "number",
      cellRenderer: SetCounter,
      flex: 1,
    },
  ];

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div
      style={{
        paddingLeft: "70px",
        paddingRight: "70px",
        paddingTop: "25px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <span style={{ fontWeight: "bold", fontSize: 24 }}>
        Управление пользователями
      </span>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <B1Button
          placeholder="Создать"
          style={{ width: "260px", height: "32px", borderRadius: "4px" }}
          onClick={() => setCreateUserModalOpen(true)}
        />
        <B1Button
          placeholder="Обновить"
          type="Secondary"
          style={{ width: "260px", height: "32px", borderRadius: "4px" }}
          onClick={handleFetch}
        />
      </div>
      <div>
        <B1Table data={data} columns={columns} style={{ height: "1000px" }} />
      </div>
      <Modal
        title={"Создание пользователя"}
        destroyOnClose={true}
        cancelText="Отмена"
        okText="Создать"
        open={isCreateUserModalOpen}
        onCancel={() => setCreateUserModalOpen(false)}
        onOk={handleUserCreate}
      >
        <ModalCreateUser
          name={name}
          surname={surname}
          email={email}
          password={password}
          setName={setName}
          setEmail={setEmail}
          setSurname={setSurname}
          setPassword={setPassword}
        />
      </Modal>
    </div>
  );
};

export default PageUsers;

const handleActivated = async (params: any) => {
  const response = await AdminService.manualActivate(
    params.data.id,
    params.value ? "remove" : "set"
  );

  if (response.data.code === 1) {
    message.success(
      `Пользователь ${params.data.name} ${params.data.surname} ${
        params.value ? "дезактивирован" : "активирован"
      }`
    );
  } else {
    message.error(
      `Возникла ошибка при попытке ${
        params.value ? "дезактивировать" : "активировать"
      } пользователя ${params.data.name} ${params.data.surname} `
    );
  }
};

const handleBlock = async (params: any) => {
  const response = await AdminService.userBlock(
    params.data.id,
    params.value ? "remove" : "set"
  );

  if (response.data.code === 1) {
    message.success(
      `Пользователь ${params.data.name} ${params.data.surname} ${
        params.value ? "разблокирован" : "заблокирован"
      }`
    );
  } else {
    message.error(
      `Возникла ошибка при попытке ${
        params.value ? "разблокировать" : "заблокировать"
      } пользователя ${params.data.name} ${params.data.surname} `
    );
  }
};

const handleAdmin = async (params: any) => {
  const response = await AdminService.setAdmin(
    params.data.id,
    params.value ? "remove" : "set"
  );

  if (response.data.code === 1) {
    message.success(
      `Пользователь ${params.data.name} ${params.data.surname} ${
        params.value ? "убран из админов" : "назначен админом"
      }`
    );
  } else {
    message.error(
      `Возникла ошибка при попытке ${
        params.value ? "убрать из админов" : "добавить в админы"
      } пользователя ${params.data.name} ${params.data.surname} `
    );
  }
};

const handleCounter = async (params: any) => {
  const response = await UserService.setRecognitionBalance(
    params.data.id,
    params.value
  );
  if (response.data.code === 1) {
    message.success(
      `Пользователю ${params.data.name} ${params.data.surname} изменен баланс на ${params.value}`
    );
  } else {
    message.error(
      `Возникла ошибка при попытке изменить баланс распознаваний на ${params.value} у пользователя ${params.data.name} ${params.data.surname} `
    );
  }
};

interface ModalCreateUser {
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  password: string | undefined;

  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ModalCreateUser: React.FC<ModalCreateUser> = ({
  name,
  surname,
  email,
  password,
  setName,
  setSurname,
  setEmail,
  setPassword,
}) => {
  return (
    <div
      style={{
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <B1Input
        placeholder="Имя"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <B1Input
        placeholder="Фамилия"
        onChange={(e) => setSurname(e.target.value)}
        value={surname}
      />
      <B1Input
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <B1Input
        placeholder="Пароль"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
    </div>
  );
};
