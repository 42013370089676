import React, { createContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routesLogged, routesUnlogged } from './app/router';
import StorageUser from './app/storage/User';

import './index.css';
import Header from './widgets/header';
import App from './app/App';

const user = new StorageUser();

interface State {
  user: StorageUser;
}

export const Context = createContext<State>({ user });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Context.Provider value={{ user }}>
    <App />
  </Context.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
