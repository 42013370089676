import $api, { API_URL } from '../../shared/api/axios';
import { AxiosResponse } from 'axios';
import { IBackResponse } from '../types/response';

export default class RecognitionService {
  static async getList(): Promise<AxiosResponse<IBackResponse>> {
    return $api.get<IBackResponse>(`${API_URL.main}/recognition/getlist`);
  }

  static async getText(id: string): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/recognition/gettext`, { id });
  }

  static async getInfo(id: string): Promise<AxiosResponse<IBackResponse>> {
    return $api.post<IBackResponse>(`${API_URL.main}/recognition/getinfo`, { id });
  }
}
